<template>
  <TitleWrapper
    :filter-option="true"
    title="STUDENT_DISCOUNT_LIST"
    tooltip-title="STUDENT_DISCOUNT_LIST_TOOLTIP"
    :display-breadcrumb="true"
    :displayed-column-options="csvFilterOptions"
    :already-selected-displayed-column="filtersPayload.displayedColumns"
    :count="filtersCount"
    @cancel="cancelHanlder"
    @applyFilter="emitFiltersPayload($event, invalid)"
    @clear="clearFilters"
  >
    <template v-slot:filterItems>
      <InputFieldWrapper>
        <UiMultiSelect
          v-model.trim="filtersPayload.student_id"
          title="STUDENTS"
          :options="studentList"
          reduce="id"
          label="full_name"
          class="flex-1 mb-5 sm:mb-0"
          input-color="bg-white"
          :already-selected="selectedStudents"
          :search-through-api="true"
          :filter="FILTERS_LIST.MULTIPLE_STUDENTS"
          @search="getStudents"
          @emitQuery="updateQuery"
        />
        <UiInput
          v-model:initial="filtersPayload.discount_amount_greater_than"
          v-model:secondary="filtersPayload.discount_amount_less_than"
          :class="$style.widthItems"
          class="flex-1"
          title="DISCOUNT_AMOUNT"
          type="range"
          placeholder="DISCOUNT_AMOUNT"
          rules="numeric"
          :filter="FILTERS_LIST.DISCOUNT_AMOUNT_RANGE"
          @emitQuery="updateQuery"
        />
      </InputFieldWrapper>
    </template>
  </TitleWrapper>
</template>

<script>
import UiInput from '@components/UiElements/UiInputBox.vue'
import UiMultiSelect from '@/src/components/UiElements/UiMultiSelect.vue'
import TitleWrapper from '@components/TitleWrapper.vue'
import { getCountFromObject } from '@utils/generalUtil'
import FILTER_KEYS, { FILTERS_LIST } from '@src/constants/filter-constants.js'
import { mapActions, mapState } from 'vuex'
import filterMixins from '@/src/mixins/filter-mixins.js'
import { buildWhereQuery } from '@/src/utils/filters'
import InputFieldWrapper from '@/src/components/UiElements/InputFieldWrapper.vue'

export default {
  components: {
    UiInput,
    UiMultiSelect,
    TitleWrapper,
    InputFieldWrapper,
  },
  mixins: [filterMixins],
  props: {
    csvFilterOptions: {
      type: Array,
      default: () => [],
    },
    alreadySelectedDisplayedColumns: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      FILTER_KEYS,
      $where: {},
      FILTERS_LIST,
      filtersPayload: {
        student_id: [],
        discount_amount_greater_than: '',
        discount_amount_less_than: '',
        displayedColumns: [],
      },
      studentList: [],
      sectionStudents: [],
      selectedStudents: [],
      filtersCount: 0,
    }
  },

  computed: {
    ...mapState({
      currentSectionScope: (state) => state.layout.currentSectionScope.id,
      currentCampusScope: (state) => state.layout.currentCampusScope.id,
    }),
  },
  watch: {
    currentCampusScope: {
      handler() {
        this.getStudents()
        this.clearFilters()
      },
    },

    currentSectionScope: {
      handler() {
        this.getStudents()
        this.clearFilters()
      },
    },
  },
  mounted() {
    this.getStudents()
    this.updateDisplayedColumnList()
  },
  methods: {
    getStudents(query) {
      let alreadySelectedStudents = []
      const payload = {
        $where: {
          ...buildWhereQuery(FILTER_KEYS.ILIKE, 'search', query),
        },
      }
      this.getStudentsWithFilters(payload).then((res) => {
        this.studentList = res?.records
        if (this.filtersPayload && this.filtersPayload.student_ids.length) {
          this.filtersPayload.student_ids.forEach((id) => {
            alreadySelectedStudents = this.studentList?.filter((record) => {
              record.id === id
            })
          })
        }
        this.selectedStudents = alreadySelectedStudents
      })
    },
    getFiltersCount() {
      this.getStudents()
      return getCountFromObject(this.filtersPayload, 'displayedColumns', 'boolean')
    },
    ...mapActions('student', ['getStudentsWithFilters']),
  },
}
</script>

<style lang="scss" module>
.widthItems {
  min-width: 30%;
}
</style>
