<template>
  <div>
    <StudentDiscountListFilter
      :csv-filter-options="displayedFilterOptions"
      :already-selected-displayed-columns="filtersData?.displayedColumns"
      @apply="applyFilters"
    />
    <div v-if="isLoading"><Loader class="mt-20 mb-40" :content="true" /></div>
    <div v-else-if="!isLoading && isEmpty(studentDiscountList)">
      <NoRecordFound />
    </div>
    <TableWrapper v-else>
      <THead>
        <TRHead>
          <TH
            v-for="head in tableHeaders.filter((head) => !head.skipForDataTableHead)"
            v-show="showColumnInDataTable(filtersData, head.dataTableName, defaultSelectedColumns)"
            :key="head.id"
          >
            {{ head.dataTableName }}
          </TH>
        </TRHead>
      </THead>
      <TBody>
        <TRBody v-for="(student, index) in studentDiscountList" :key="index">
          <TD v-show="showColumnInDataTable(filtersData, 'Student', ['Student'])">
            <SingleUserDisplay :user="student" :image="true" label="full_name" />
          </TD>
          <TD v-show="showColumnInDataTable(filtersData, 'Total Discount', ['Total Discount'])">
            <span
              class="cursor-pointer text-primary-purple-600"
              @click="redirectToDetails(student)"
            >
              {{ student.total_discount || GENERAL_CONSTANTS.NOT_APPLICABLE }}
            </span>
          </TD>
        </TRBody>
      </TBody>
    </TableWrapper>

    <Pagination
      v-if="showPagination"
      v-show="!isLoading"
      :record-limit="filteredRecordLimit"
      :max-range="paginationCounts(studentDiscountListCount, filteredRecordLimit)"
      @filterRecord="fetchstudentDiscountList"
    />
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import Loader from '@components/BaseComponent/Loader.vue'
import GeneralMixin from '@src/mixins/general-mixins.js'
import Pagination from '@components/BaseComponent/Pagination.vue'
import GENERAL_CONSTANTS from '@src/constants/general-constants'
import SingleUserDisplay from '@src/components/UiElements/SingleUserDisplay.vue'
import { paginationRangeHandler } from '@src/components/BaseComponent/pagination.util.js'
import StudentDiscountListFilter from '@src/router/views/finance/fees/discount/StudentDiscountListFilter.vue'
import studentDiscountListData from '@src/router/views/finance/fees/discount/studentDiscountList.json'
import scrollMixin from '@src/mixins/scroll-mixin'
import fileMixins from '@/src/mixins/file-mixins'
import isEmpty from 'lodash/isEmpty'
import {
  removeEmptyKeysFromObject,
  objectDeepCopy,
  findCurrencyById,
  showColumnInDataTable,
} from '@utils/generalUtil'
import NoRecordFound from '@components/BaseComponent/NoRecordFound.vue'
import {
  TableWrapper,
  TBody,
  TD,
  TH,
  THead,
  TRBody,
  TRHead,
} from '@src/components/UiElements/TableElements/index.js'

export default {
  name: 'SalaryBonusTypeView',
  components: {
    StudentDiscountListFilter,
    Loader,
    Pagination,
    SingleUserDisplay,
    NoRecordFound,
    TableWrapper,
    TBody,
    TD,
    TH,
    THead,
    TRBody,
    TRHead,
  },
  mixins: [GeneralMixin, fileMixins, scrollMixin],
  data() {
    return {
      showPagination: false,
      dashboard: 'dashboard',
      filtersData: {},
      studentDiscountList: [],
      studentDiscountListCount: GENERAL_CONSTANTS.EMPTY_RECORD,
      filteredRecordLimit: GENERAL_CONSTANTS.RECORD_LIMIT,
      GENERAL_CONSTANTS,
      tableHeaders: studentDiscountListData.tableHeaders,
      defaultSelectedColumns: studentDiscountListData.defaultSelectedColumns,
      isEmpty,
    }
  },
  computed: {
    ...mapState({
      isLoading: (state) => state.layout.isLoading,
      currentCampusScope: (state) => state.layout.currentCampusScope,
      currentSectionScope: (state) => state.layout.currentSectionScope,
    }),
  },
  watch: {
    currentSectionScope: {
      handler(val) {
        this.fetchstudentDiscountList()
      },
    },
    currentCampusScope: {
      handler(val) {
        if (val) this.fetchstudentDiscountList()
      },
    },
  },
  /**
   * Mounted hook
   * @description Setting static right bar data
   */
  mounted() {
    this.fetchstudentDiscountList()
    this.setRightBar()
  },
  methods: {
    showColumnInDataTable,
    applyFilters(filtersPayload) {
      this.filtersData = objectDeepCopy(filtersPayload)
      this.fetchstudentDiscountList()
    },
    redirectToDetails(detail) {
      this.$router?.push({ name: 'student-discounts-detail', params: { id: detail.id } })
    },

    async fetchstudentDiscountList(range) {
      if (!this.currentCampusScope) return
      let paginationRange = paginationRangeHandler(range)
      let payload = {
        ...paginationRange,
      }
      payload = this.filtersData ? { ...payload, ...{ $where: this.filtersData?.$where } } : payload
      removeEmptyKeysFromObject(payload)
      const [response, err] = await this.getStudentDiscountList(payload)
      if (!err) {
        this.studentDiscountList = response.data.records
        this.studentDiscountListCount = response.data?.meta?.total_records
        this.showPagination = this.studentDiscountListCount > GENERAL_CONSTANTS.RECORD_LIMIT

        this.getFeeDiscountStats(payload).then((statsResponse) => {
          let stats = statsResponse.data
          this.setRightBar(stats)
        })
      }
    },

    setRightBar(stats, records) {
      const rightBarContent = {
        header: {
          title: 'STUDENT_DISCOUNT_LIST',
        },
        stats: [
          {
            title: GENERAL_CONSTANTS.STATS_AND_SUMMARY,
            categories: [
              {
                text: 'DISCOUNTS_SACTIONED',
                value: stats ? stats.total_discounted_students : GENERAL_CONSTANTS.EMPTY_RECORD,
                tooltipDescription: 'Total No. of Students attain Discounts',
              },
              {
                text: 'TOTAL_DISCOUNTED_AMOUNT',
                currency: findCurrencyById()?.symbol,
                value: stats ? stats.total_discount : GENERAL_CONSTANTS.EMPTY_RECORD,
                tooltipDescription: 'Total discounted amount implied on student',
              },
            ],
          },
        ],
      }
      this.setRightbarContent(rightBarContent)
    },

    ...mapActions('fee', ['getStudentDiscountList', 'getFeeDiscountStats']),
    ...mapActions('layout', ['setRightbarContent']),
  },
}
</script>

<style lang="scss" scoped>
tr:nth-child(even) {
  background-color: var(--bg-color-light);
}
.showModal-body tr:nth-child(even) {
  background-color: inherit;
}
</style>
